import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Table, Tooltip } from 'flowbite-react';
import { RiNewspaperFill } from 'react-icons/ri';
import { LuImage } from 'react-icons/lu';
import { RiDeleteBin6Line } from 'react-icons/ri';
import { FaSearch, FaSort } from 'react-icons/fa';
import { gql, useMutation, useLazyQuery } from '@apollo/client';
import ConfirmationModal from './ConfirmationModal';
import AddCitationModal, { UPDATE_CITATION } from './AddCitationModal';
import {
  citationStatusOptions,
  tableTheme,
  toastConfig,
} from '../utils/constants';
import { useAuth } from '../context/AuthContext';
import { toast } from 'react-toastify';
import TableLoadingComponent from './TableLoadingComponent';
import { formatDateTime, getChangedLabel } from '../utils/helperFunctions';
import DropdownCell from './DropdownCell';
import StatusFilterDropdown from './StatusFilterDropdown';
import { debounce } from 'lodash';
import ActivatePermitModal from './ActivatePermitModal';
import { CREATE_PAYMENT_SESSION } from './PermitsTable';

const GET_ALL_CITATIONS = gql`
  query GetAllCitations(
    $municipalityId: String!
    $page: Int!
    $limit: Int!
    $sortOptions: [SortOptionInput!]
    $searchQuery: String
    $citationStatus: [CitationStatus!]
  ) {
    getAllCitations(
      getAllCitationsInput: {
        municipalityId: $municipalityId
        pagination: { page: $page, limit: $limit }
        sortOptions: $sortOptions
        searchQuery: $searchQuery
        citationStatus: $citationStatus
      }
    ) {
      citations {
        id
        color
        description
        licensePlateNumber
        licensePlateState
        date
        time
        make
        modal
        photo
        fineAmount
        location
        locationResult
        citationNumber
        userId
        violationId
        municipalityId
        createdAt
        updatedAt
        citationStatus
        violation {
          id
          code
          title
          fineAmount
          municipalityId
          createdAt
          updatedAt
        }
        user {
          id
          name
        }
        permit {
          permitNumber
        }
      }
      pagination {
        page
        total
      }
      statusCounts {
        status
        count
      }
    }
  }
`;
const REMOVE_CITATION = gql`
  mutation RemoveCitation($id: String!) {
    removeCitation(id: $id) {
      success
      message
    }
  }
`;

const CitationsTable = () => {
  const limit = 12;
  const [page, setPage] = useState(1);
  const { currentUser, organizationType } = useAuth();
  const [updateCitation] = useMutation(UPDATE_CITATION);
  const [sortOptions, setSortOption] = useState({
    column: 'citationNumber',
    direction: 'DESC',
  });
  const [getAllCitations] = useLazyQuery(GET_ALL_CITATIONS, {
    fetchPolicy: 'network-only',
  });
  const [hasMore, setHasMore] = useState(true);
  const [paymentModalVisible, setPaymentModalVisible] = useState(false);
  const [newCitationId, setNewCitationId] = useState();
  const [statusCounts, setStatusCounts] = useState();
  const [reportsArray, setReportsArray] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [addModalVisible, setAddModalVisible] = useState(false);
  const [deleteCitationData, setDeleteCitationData] = useState();
  const [selectedCitation, setSelectedCitation] = useState();
  const [loading, setLoading] = useState(false);
  const [fetchingMore, setFetchingMore] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedStatus, setSelcetedStatus] = useState('');
  const [currentCitationId, setCurrentCitationId] = useState();
  const [isLastCellHovered, setIsLastCellHovered] = useState(false);

  const inputRef = useRef(null);

  const [removeCitationMutation, { loading: removing }] =
    useMutation(REMOVE_CITATION);
  const changeStatus = async (id, status) => {
    try {
      const citationData = {};
      let response;

      response = await updateCitation({
        variables: {
          input: {
            ...citationData,
            municipalityId: currentUser?.municipalityId,
            id: id,
            citationStatus: status,
          },
        },
      });
      setNewCitationId(response?.data?.createCitation?.id);
    } catch (error) {
      console.error('Error adding citation:', error);
    }
  };

  useEffect(() => {
    fetchCitations();
  }, []);
  const refetch = sortConfig => {
    fetchCitations(sortConfig);
    setHasMore(true);
  };

  const fetchCitations = async (sortConfig, query, filterStatus) => {
    setPage(1);
    try {
      setLoading(true);
      const { data } = await getAllCitations({
        variables: {
          municipalityId: currentUser.municipalityId,
          page: 1,
          limit: limit,
          sortOptions: sortConfig ? [sortConfig] : [sortOptions],
          searchQuery: query,
          citationStatus: filterStatus ? [filterStatus] : null,
        },
      });
      setStatusCounts(data?.getAllCitations?.statusCounts);
      setReportsArray(data?.getAllCitations?.citations);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  // Debounce the search function
  const debouncedSearch = useCallback(
    debounce(query => fetchCitations(null, query, selectedStatus), 500),
    [selectedStatus]
  );

  const handleChange = e => {
    const query = e.target.value;
    setSearchTerm(query);
    debouncedSearch(query);
  };

  const tableContainerRef = useRef();
  const handleScroll = useCallback(async () => {
    const container = tableContainerRef.current;
    if (
      container.scrollTop + container.clientHeight >= container.scrollHeight &&
      hasMore
    ) {
      try {
        setFetchingMore(true);
        setPage(prev => prev + 1);
        const { data } = await getAllCitations({
          variables: {
            page: page + 1,
            municipalityId: currentUser.municipalityId,
            limit: limit,
            sortOptions: [sortOptions],
          },
        });
        setReportsArray(prev => [...prev, ...data.getAllCitations.citations]);
        setHasMore(
          data.getAllCitations.citations?.length > 0 &&
            data.getAllCitations.citations?.length <
              data.getAllCitations.pagination.total
        );
      } catch (error) {
      } finally {
        setFetchingMore(false);
      }
    }
  }, [currentUser.municipalityId, getAllCitations, hasMore, page, sortOptions]);

  useEffect(() => {
    const container = tableContainerRef.current;
    container.addEventListener('scroll', handleScroll);
    return () => container.removeEventListener('scroll', handleScroll);
  }, [page, hasMore, sortOptions, handleScroll]);

  useEffect(() => {
    if (newCitationId) {
      setTimeout(() => {
        setNewCitationId(null);
      }, 3000);
    }
  }, [newCitationId]);
  const removeCitation = async () => {
    try {
      await removeCitationMutation({
        variables: { id: deleteCitationData.id },
      });
      refetch();
      setModalVisible(false);
      setSelectedCitation(null);
      toast.success('Citation deleted successfully', toastConfig);
    } catch (error) {
      toast.error('Error removing citation', toastConfig);
    }
  };
  const [createPaymentSession] = useMutation(CREATE_PAYMENT_SESSION, {
    fetchPolicy: 'network-only',
  });
  const getPaymentIntent = async citationId => {
    try {
      const { data } = await createPaymentSession({
        variables: {
          citationId,
          successUrl: 'https://dashboard.enforceapp.com',
          cancelUrl: 'https://dashboard.enforceapp.com',
        },
      });
      const checkoutUrl = data?.createCheckoutSession;
      if (checkoutUrl) {
        window.location.href = checkoutUrl;
      } else {
        console.error('No checkout URL received from the backend.');
      }
    } catch (error) {
      console.log(error);
    }
  };

  const sortReports = key => {
    if (sortOptions.column === key) {
      if (sortOptions.direction === 'DESC') {
        setSortOption({
          column: key,
          direction: 'ASC',
        });
        refetch({
          column: key,
          direction: 'ASC',
        });
      } else {
        setSortOption({
          column: key,
          direction: 'DESC',
        });
        refetch({
          column: key,
          direction: 'DESC',
        });
      }
    } else {
      setSortOption({
        column: key,
        direction: 'ASC',
      });
      refetch({
        column: key,
        direction: 'ASC',
      });
    }
  };
  useEffect(() => {
    const handleKeyDown = event => {
      if (event.key === 'Escape') {
        event.preventDefault();
        setAddModalVisible(false);
        setModalVisible(false);
        setSelectedCitation(null);
        setPaymentModalVisible(false);
        document.activeElement.blur();
      }
    };

    if (addModalVisible || modalVisible || paymentModalVisible) {
      document.addEventListener('keydown', handleKeyDown);
    } else {
      document.removeEventListener('keydown', handleKeyDown);
    }

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [addModalVisible, modalVisible, paymentModalVisible]);
  useEffect(() => {
    inputRef.current?.focus();
  }, [searchTerm]);

  return (
    <div className="px-10 pt-20">
      <div className="flex flex-row justify-between py-6">
        <div className="flex flex-row items-center">
          <div className="relative">
            <input
              ref={inputRef}
              type="text"
              placeholder="Search"
              value={searchTerm}
              onChange={handleChange}
              className="bg-[#FAFAFA] border-1 border-[rgba(0,0,0,0.1)]focus:outline-none focus:ring-1 focus:ring-[rgba(0,0,0,0.2)] focus:border-transparent h-[36px] w-[321px] pl-16 mr-4 border text-sm font-semibold border-gray-300 rounded-full placeholder-gray-500 ring-0 "
            />
            <FaSearch className="absolute left-9 top-1/2 transform -translate-y-1/2 text-gray-500 " />
          </div>
          <StatusFilterDropdown
            statusOptions={citationStatusOptions}
            statusCounts={statusCounts}
            onStatusChange={selectedStatus => {
              setSelcetedStatus(selectedStatus);
              fetchCitations(null, searchTerm, selectedStatus);
            }}
          />
        </div>
        <button
          onClick={() => setAddModalVisible(true)}
          className="h-[36px] bg-[#FACE4F] focus:ring-0 focus:ring-[#FACE4F] flex flex-row items-center px-8 py-3 rounded-full hover:bg-[#D9B345]"
        >
          <RiNewspaperFill />
          <span className="pl-3 text-sm font-bold">Add Citation</span>
        </button>
      </div>
      <div
        ref={tableContainerRef}
        className="overflow-auto"
        style={{ maxHeight: 'calc(100vh - 195px)' }}
      >
        {!loading && reportsArray?.length === 0 ? (
          <div
            style={{ height: 'calc(100vh - 195px)' }}
            className="bg-[rgba(0,0,0,0.02)] flex justify-center items-center flex-1"
          >
            <span className=" text-lg text-[#374151] font-bold w-[180px] text-center">
              {searchTerm
                ? 'No citations found'
                : 'You don’t have any citations yet'}
            </span>
          </div>
        ) : (
          <Table className="group" id="citation-table" theme={tableTheme}>
            <Table.Head>
              <Table.HeadCell
                className="px-2 cursor-pointer sticky top-0 bg-[rgba(0,0,0,0.05)] backdrop-filter backdrop-blur-md"
                onClick={() => sortReports('citationNumber')}
              >
                <div className="flex items-center">
                  <span>Citation #</span>
                  <FaSort className="ml-2 text-gray-500" />
                </div>
              </Table.HeadCell>
              <Table.HeadCell
                className="px-2 cursor-pointer sticky top-0 bg-[rgba(0,0,0,0.05)] backdrop-filter backdrop-blur-md"
                onClick={() => sortReports('createdAt')}
              >
                <div className="flex items-center">
                  <span>Timestamp</span>
                  <FaSort className="ml-2 text-gray-500" />
                </div>
              </Table.HeadCell>
              <Table.HeadCell
                className="px-2 cursor-pointer sticky top-0 bg-[rgba(0,0,0,0.05)] backdrop-filter backdrop-blur-md"
                onClick={() => sortReports('userId')}
              >
                <div className="flex items-center">
                  <span>Officer Name</span>
                  <FaSort className="ml-2 text-gray-500" />
                </div>
              </Table.HeadCell>
              <Table.HeadCell className="px-2 sticky top-0 bg-[rgba(0,0,0,0.05)] backdrop-filter backdrop-blur-md">
                Violation
              </Table.HeadCell>
              <Table.HeadCell className="px-2 sticky top-0 bg-[rgba(0,0,0,0.05)] backdrop-filter backdrop-blur-md">
                Location
              </Table.HeadCell>
              <Table.HeadCell className="px-2 sticky top-0 bg-[rgba(0,0,0,0.05)] backdrop-filter backdrop-blur-md">
                Vehicle
              </Table.HeadCell>
              <Table.HeadCell className="px-2 sticky top-0 bg-[rgba(0,0,0,0.05)] backdrop-filter backdrop-blur-md">
                License Plate
              </Table.HeadCell>
              <Table.HeadCell className="px-2 sticky top-0 bg-[rgba(0,0,0,0.05)] backdrop-filter backdrop-blur-md">
                Description
              </Table.HeadCell>
              <Table.HeadCell className="px-2 sticky top-0 bg-[rgba(0,0,0,0.05)] backdrop-filter backdrop-blur-md">
                Fee
              </Table.HeadCell>
              <Table.HeadCell
                onClick={() => sortReports('citationStatus')}
                className="px-2 cursor-pointer sticky top-0 bg-[rgba(0,0,0,0.05)] backdrop-filter backdrop-blur-md"
              >
                <div className="flex items-center">
                  <span>Status</span>
                  <FaSort className="ml-2 text-gray-500" />
                </div>
              </Table.HeadCell>
              <Table.HeadCell className="px-2 sticky top-0 bg-[rgba(0,0,0,0.05)] backdrop-filter backdrop-blur-md" />
              <Table.HeadCell className="px-2 sticky top-0 bg-[rgba(0,0,0,0.05)] backdrop-filter backdrop-blur-md" />
            </Table.Head>

            <Table.Body>
              {loading ? (
                <TableLoadingComponent numberofColumns={10} />
              ) : (
                reportsArray?.map(report => {
                  const isHighlighted = newCitationId === report.id;
                  return (
                    <Table.Row
                      onClick={() => {
                        setAddModalVisible(true);
                        setSelectedCitation(report);
                      }}
                      key={report.citationNumber}
                      className={`cursor-pointer group even:bg-[rgba(0,0,0,0.05)] ${
                        isLastCellHovered ? '' : 'hover:bg-[rgba(0,0,0,0.1)]'
                      } ${isHighlighted ? 'animate-fadeHighlight' : ''}`}
                      style={{
                        animation: isHighlighted
                          ? 'fadeHighlight 3s ease-in-out'
                          : 'none',
                      }}
                    >
                      <Table.Cell className="whitespace-nowrap">
                        {report.citationNumber || '---'}
                      </Table.Cell>
                      <Table.Cell className="whitespace-nowrap">
                        {formatDateTime(report.date, report.time) || '---'}
                      </Table.Cell>
                      <Table.Cell className="whitespace-nowrap">
                        {report.user.name || '---'}
                      </Table.Cell>
                      <Table.Cell className="whitespace-nowrap">
                        {report.violation.title || '---'}
                      </Table.Cell>
                      <Table.Cell className="whitespace-nowrap">
                        {report?.location.address
                          ? report?.location?.address
                          : report?.locationResult.name
                            ? `${report?.locationResult.name || ''}, ${report?.locationResult.postalCode || ''}`
                            : '---'}
                      </Table.Cell>
                      <Table.Cell className="whitespace-nowrap">
                        <span className="max-w-56 text-wrap">
                          {report.color || report.make || report.modal
                            ? `${report.color} ${report.make} ${report.modal}`
                            : '---'}
                        </span>
                      </Table.Cell>
                      <Table.Cell className="whitespace-wrap max-w-36">
                        {report.licensePlateState || report.licensePlateNumber
                          ? `${report.licensePlateState} ${report.licensePlateNumber}`
                          : '---'}
                      </Table.Cell>
                      <Table.Cell className="whitespace-nowrap">
                        {report.description || '---'}
                      </Table.Cell>
                      <Table.Cell className="whitespace-nowrap">
                        {report.fineAmount ? `$${report.fineAmount}` : '---'}
                      </Table.Cell>
                      <Table.Cell
                        className="whitespace-nowrap w-[240px]"
                        onClick={e => e.stopPropagation()}
                      >
                        <span
                          onMouseEnter={() => setIsLastCellHovered(true)}
                          onMouseLeave={() => setIsLastCellHovered(false)}
                        >
                          <DropdownCell
                            statusesRequiringConfirmation={['PAID']}
                            defaultStatus={report?.citationStatus?.toUpperCase()}
                            statusOptions={citationStatusOptions}
                            id={report?.id}
                            onStatusChange={(id, newStatus) => {
                              if (newStatus === 'PAID') {
                                setCurrentCitationId(id);
                                setPaymentModalVisible(true);
                              } else {
                                changeStatus(id, newStatus);
                              }
                            }}
                          />
                        </span>
                      </Table.Cell>
                      <Table.Cell>
                        {report.photo ? (
                          <button
                            className="p-2 rounded-md hover:text-black focus:outline-none"
                            onClick={() => window.open(report.photo, '_blank')}
                          >
                            <LuImage className="w-5 h-5" />
                          </button>
                        ) : (
                          '---'
                        )}
                      </Table.Cell>
                      <Table.Cell
                        onClick={event => {
                          event.stopPropagation();
                        }}
                      >
                        {report?.permit ? (
                          <Tooltip
                            content={`${getChangedLabel(organizationType, true)} #${report?.permit?.permitNumber}`}
                            trigger="hover"
                          >
                            <span
                              onMouseEnter={() => setIsLastCellHovered(true)}
                              onMouseLeave={() => setIsLastCellHovered(false)}
                              className="text-lg  cursor-default font-bold hover:text-black "
                            >
                              P
                            </span>
                          </Tooltip>
                        ) : (
                          <></>
                        )}
                      </Table.Cell>
                    </Table.Row>
                  );
                })
              )}
              {fetchingMore && (
                <TableLoadingComponent numberofRows={4} numberofColumns={10} />
              )}
            </Table.Body>
          </Table>
        )}
      </div>

      {/* Modals */}
      <ActivatePermitModal
        content={{
          heading: `Pay Citaiton?`,
          description1: (
            <>
              If the customer is paying for the citation with a{' '}
              <strong>credit card</strong>, press “Take Payment” - this will
              redirect you to the checkout screen to complete the transaction.
            </>
          ),
          description2: (
            <>
              If the customer is using any{' '}
              <strong>other form of payment</strong> outside Dashboard (e.g.
              check, cash, money order, etc), press “No Payment”, and the{' '}
              citation will be activated immediately.
            </>
          ),
        }}
        isOpen={paymentModalVisible}
        onClose={() => setPaymentModalVisible(false)}
        onConfirm={() => getPaymentIntent(currentCitationId)}
        onNoPayment={() => {
          changeStatus(currentCitationId, 'PAID');
          setPaymentModalVisible(false);
          refetch();
        }}
      />
      <ConfirmationModal
        title={'Delete Citation?'}
        isOpen={modalVisible}
        onClose={() => setModalVisible(false)}
        onConfirm={removeCitation}
        message={`Are you sure you want to delete citation #${deleteCitationData?.citationNumber}?`}
        loading={removing}
        icon={<RiDeleteBin6Line className="w-5 h-5 mr-1" />}
      />
      <AddCitationModal
        setDeleteCitationData={setDeleteCitationData}
        setModalVisible={setModalVisible}
        setNewCitationId={setNewCitationId}
        refetchCitations={refetch}
        citationDataFromProps={selectedCitation}
        isOpen={addModalVisible}
        onClose={() => {
          setSelectedCitation(null);
          setAddModalVisible(false);
        }}
      />
    </div>
  );
};

export default CitationsTable;
