import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Table, Tooltip } from 'flowbite-react';
import { FaAddressCard, FaSearch, FaSort } from 'react-icons/fa';
import AddPermitModal from './AddPermitModal';
import ConfirmationModal from './ConfirmationModal';
import {
  permitStatusOptions,
  tableTheme,
  toastConfig,
} from '../utils/constants';
import { MdOutlineTimer } from 'react-icons/md';
import { gql, useLazyQuery, useMutation } from '@apollo/client';
import { useAuth } from '../context/AuthContext';
import { toast } from 'react-toastify';
import TableLoadingComponent from './TableLoadingComponent';
import StatusFilterDropdown from './StatusFilterDropdown';
import { debounce } from 'lodash';
import DropdownCell from './DropdownCell';
import ActivatePermitModal from './ActivatePermitModal';
import { getChangedLabel } from '../utils/helperFunctions';
import { RiDeleteBin6Line } from 'react-icons/ri';

const REFUND_PAYMENT = gql`
  mutation RefundPayment($refundPaymentInput: RefundPaymentInput!) {
    refundPayment(refundPaymentInput: $refundPaymentInput) {
      success
      message
    }
  }
`;
export const UPDATE_PERMIT_STATUS = gql`
  mutation UpdatePermitStatus($updatePermitInput: UpdatePermitInput!) {
    updatePermitStatus(updatePermitInput: $updatePermitInput) {
      id
    }
  }
`;
const GET_ALL_PERMITS = gql`
  query GetAllPermits(
    $municipalityId: String!
    $page: Int!
    $limit: Int!
    $sortOptions: [SortOptionInput!]
    $searchQuery: String
    $permitStatus: [PermitStatus!]
  ) {
    getAllPermits(
      getAllPermitsInput: {
        municipalityId: $municipalityId
        pagination: { page: $page, limit: $limit }
        sortOptions: $sortOptions
        searchQuery: $searchQuery
        permitStatus: $permitStatus
      }
    ) {
      permits {
        id
        permitNumber
        color
        licensePlateNumber
        licensePlateState
        make
        modal
        fee
        ownerCity
        ownerAddress
        ownerName
        term
        ownerZip
        ownerState
        ownerDOB
        effectiveDate
        expirationDate
        permitStatus
        municipalityId
        parkingPermitId
        ownerEmail
        ownerPhone
        customerType
        customerId
        comments
        renewable
        paidByStripe
        createdAt
        updatedAt
      }
      pagination {
        page
        total
      }
      statusCounts {
        status
        count
      }
    }
  }
`;
export const CREATE_PAYMENT_SESSION = gql`
  mutation CreateCheckoutSession(
    $citationId: String
    $permitId: String
    $successUrl: String
    $cancelUrl: String
  ) {
    createCheckoutSession(
      createCheckoutSessionInput: {
        citationId: $citationId
        permitId: $permitId
        successUrl: $successUrl
        cancelUrl: $cancelUrl
      }
    )
  }
`;
const REMOVE_PERMIT = gql`
  mutation RemovePermit($id: String!) {
    removePermit(id: $id) {
      success
      message
    }
  }
`;

const PermitsTable = () => {
  const [page, setPage] = useState(1);
  const limit = 10;
  const tableContainerRef = useRef();
  const [sortOptions, setSortOption] = useState({
    column: 'permitNumber',
    direction: 'DESC',
  });
  const [addModalVisible, setAddModalVisible] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [fetchingMore, setFetchingMore] = useState(false);
  const [currentPermitId, setCurrentPermitId] = useState();
  const [newPermitId, setNewPermitId] = useState();
  const { currentUser, organizationType } = useAuth();
  const [hasMore, setHasMore] = useState(true);
  const [permitsArray, setPermitsArray] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedFilterStatus, setSelectedFilterStatus] = useState('');
  const [statusCounts, setStatusCounts] = useState();
  const [selectedPermit, setSelectedPermit] = useState();
  const [totalPermits, setTotalPermits] = useState();
  const [paymentModalVisible, setPaymentModalVisible] = useState(false);
  const [showRefundInput, setShowRefundInput] = useState(false);
  const [deletePermitData, setDeletePermitData] = useState();
  const [isStatusCellHovered, setIsStatusCellHovered] = useState(false);

  const maxPermit = currentUser?.municipality?.maxPermit;
  const [updatePermitStatus, { loading: updatingStatus }] =
    useMutation(UPDATE_PERMIT_STATUS);
  const [getAllPermits] = useLazyQuery(GET_ALL_PERMITS, {
    fetchPolicy: 'network-only',
  });
  const [removePermitMutation, { loading: removing }] =
    useMutation(REMOVE_PERMIT);
  const [refundPayment, { loading: refundingPayment }] =
    useMutation(REFUND_PAYMENT);

  const handleRefund = async refundAmount => {
    try {
      await refundPayment({
        variables: {
          refundPaymentInput: {
            permitId: currentPermitId,
            amount: Number(refundAmount),
          },
        },
      });
      changeStatus(currentPermitId, 'EXPIRED');
      refetch();
      toast.success('Refund initiated successfully', toastConfig);
      setModalVisible(false);
    } catch (error) {
      toast.error(error?.message || 'Error initiating the refund', toastConfig);
      console.log('ERRRR', error);
    }
  };
  const fetchPermits = async (sortConfig, query, filterStatus) => {
    setPage(1);
    try {
      setLoading(true);
      const { data } = await getAllPermits({
        variables: {
          municipalityId: currentUser.municipalityId,
          page: 1,
          limit: limit,
          sortOptions: [sortOptions],
          searchQuery: query,
          permitStatus: filterStatus ? [filterStatus] : null,
        },
      });
      setStatusCounts(data?.getAllPermits?.statusCounts);
      setPermitsArray(data?.getAllPermits?.permits);
      setTotalPermits(data?.getAllPermits?.pagination?.total);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  const [createPaymentSession] = useMutation(CREATE_PAYMENT_SESSION, {
    fetchPolicy: 'network-only',
  });
  const getPaymentIntent = async permitId => {
    try {
      const { data } = await createPaymentSession({
        variables: {
          permitId,
          successUrl: 'https://dashboard.enforceapp.com/?defaultTab=permits',
          cancelUrl: 'https://dashboard.enforceapp.com/?defaultTab=permits',
        },
      });
      const checkoutUrl = data?.createCheckoutSession;
      if (checkoutUrl) {
        window.location.href = checkoutUrl;
      } else {
        console.error('No checkout URL received from the backend.');
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchPermits();
  }, []);
  const debouncedSearch = useCallback(
    debounce(query => fetchPermits(null, query, selectedFilterStatus), 500),
    [selectedFilterStatus]
  );

  const handleChange = e => {
    const query = e.target.value;
    setSearchTerm(query);
    debouncedSearch(query);
  };
  const refetch = sortConfig => {
    fetchPermits(sortConfig);
  };
  const handleScroll = useCallback(async () => {
    const container = tableContainerRef.current;
    if (
      container.scrollTop + container.clientHeight >= container.scrollHeight &&
      hasMore
    ) {
      try {
        setFetchingMore(true);
        setPage(prev => prev + 1);
        const { data } = await getAllPermits({
          variables: {
            page: page + 1,
            municipalityId: currentUser.municipalityId,
            limit: limit,
            sortOptions: [sortOptions],
          },
        });
        setPermitsArray(prev => [...prev, ...data.getAllPermits.permits]);
        setHasMore(
          data.getAllPermits.permits?.length > 0 &&
            data.getAllPermits.permits?.length <
              data.getAllPermits.pagination.total
        );
      } catch (error) {
      } finally {
        setFetchingMore(false);
      }
    }
  }, [currentUser.municipalityId, getAllPermits, hasMore, page, sortOptions]);
  useEffect(() => {
    if (newPermitId) {
      setTimeout(() => {
        setNewPermitId(null);
      }, 3000);
    }
  }, [newPermitId]);
  useEffect(() => {
    const container = tableContainerRef.current;
    container.addEventListener('scroll', handleScroll);
    return () => container.removeEventListener('scroll', handleScroll);
  }, [page, hasMore, sortOptions, handleScroll]);

  const renderParkingPermit = parkingPermitId => {
    const selectedZone = currentUser?.municipality?.parkingPermits.find(
      p => p.id === parkingPermitId
    );
    return <Table.Cell>{selectedZone?.zone || '---'}</Table.Cell>;
  };
  const sortPermits = key => {
    if (sortOptions.column === key) {
      if (sortOptions.direction === 'DESC') {
        setSortOption({
          column: key,
          direction: 'ASC',
        });
        refetch({
          column: key,
          direction: 'ASC',
        });
      } else {
        setSortOption({
          column: key,
          direction: 'DESC',
        });
        refetch({
          column: key,
          direction: 'DESC',
        });
      }
    } else {
      setSortOption({
        column: key,
        direction: 'ASC',
      });
      refetch({
        column: key,
        direction: 'ASC',
      });
    }
  };
  const changeStatus = async (id, status) => {
    try {
      await updatePermitStatus({
        variables: {
          updatePermitInput: { permitId: id, permitStatus: status },
        },
      });
      setModalVisible(false);
    } catch (error) {
      toast.error('Error updating permit', toastConfig);
    }
  };
  const removePermit = async () => {
    try {
      await removePermitMutation({
        variables: { id: deletePermitData.id },
      });
      refetch();
      setDeleteModalVisible(false);
      setSelectedPermit(null);
      toast.success(
        `${getChangedLabel(organizationType, true)} deleted successfully`,
        toastConfig
      );
    } catch (error) {
      toast.error('Error removing citation', toastConfig);
    }
  };

  useEffect(() => {
    const handleKeyDown = event => {
      if (event.key === 'Escape') {
        event.preventDefault();
        setAddModalVisible(false);
        setModalVisible(false);
        setPaymentModalVisible(false);
        document.activeElement.blur();
      }
    };
    if (addModalVisible || modalVisible || paymentModalVisible) {
      document.addEventListener('keydown', handleKeyDown);
    } else {
      document.removeEventListener('keydown', handleKeyDown);
    }
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [addModalVisible, modalVisible, paymentModalVisible]);

  return (
    <div className="px-10 pt-20">
      <div className="flex flex-row justify-between py-6">
        <div className="flex flex-row items-center ">
          <div className="relative">
            <input
              type="text"
              placeholder="Search"
              value={searchTerm}
              onChange={handleChange}
              className="bg-[#FAFAFA] border-1 border-[rgba(0,0,0,0.1)]focus:outline-none focus:ring-1 focus:ring-[rgba(0,0,0,0.2)] focus:border-transparent h-[36px] w-[321px] pl-16 mr-4 border text-sm font-semibold border-gray-300 rounded-full placeholder-gray-500 ring-0 "
            />
            <FaSearch className="absolute left-9 top-1/2 transform -translate-y-1/2 text-gray-500 " />
          </div>
          <StatusFilterDropdown
            statusOptions={permitStatusOptions}
            statusCounts={statusCounts}
            onStatusChange={selectedStatus => {
              setSelectedFilterStatus(selectedStatus);
              fetchPermits(null, searchTerm, selectedStatus);
            }}
          />
        </div>
        {totalPermits >= maxPermit ? (
          <Tooltip
            content={`${maxPermit} active ${getChangedLabel(organizationType, false, true)} allowed max`}
            trigger="hover"
          >
            <button
              disabled={true}
              onClick={() => setAddModalVisible(true)}
              className="h-[36px] bg-[#FACE4F] flex flex-row items-center px-8 py-3 rounded-full hover:bg-[#D9B345] disabled:opacity-50"
            >
              <FaAddressCard />
              <span className="pl-3 text-sm font-bold">
                Add {getChangedLabel(organizationType, true)}
              </span>
            </button>
          </Tooltip>
        ) : (
          <button
            onClick={() => setAddModalVisible(true)}
            className="h-[36px] bg-[#FACE4F] flex flex-row items-center px-8 py-3 rounded-full hover:bg-[#D9B345] disabled:opacity-50"
          >
            <FaAddressCard />
            <span className="pl-3 text-sm font-bold">
              Add {getChangedLabel(organizationType, true)}
            </span>
          </button>
        )}
      </div>
      <div
        ref={tableContainerRef}
        className="overflow-auto mb-5"
        style={{ maxHeight: 'calc(100vh - 195px)' }}
      >
        {!loading && permitsArray?.length === 0 ? (
          <div
            style={{ height: 'calc(100vh - 195px)' }}
            className="bg-[rgba(0,0,0,0.02)] flex justify-center items-center flex-1"
          >
            <span className=" text-lg text-[#374151] font-bold w-[180px] text-center">
              {searchTerm
                ? `No ${getChangedLabel(organizationType, false, true)} found`
                : `You don’t have any ${getChangedLabel(organizationType, false, true)} yet`}
            </span>
          </div>
        ) : (
          <Table id="permit-table" theme={tableTheme}>
            <Table.Head>
              <Table.HeadCell
                onClick={() => sortPermits('permitNumber')}
                className="px-2 cursor-pointer sticky top-0 bg-[rgba(0,0,0,0.05)] backdrop-filter backdrop-blur-md"
              >
                <div className="flex items-center">
                  <span>{getChangedLabel(organizationType, true)} #</span>
                  <FaSort className="ml-2 text-gray-500" />
                </div>
              </Table.HeadCell>
              <Table.HeadCell
                onClick={() => sortPermits('ownerName')}
                className="px-2 cursor-pointer sticky top-0 bg-[rgba(0,0,0,0.05)] backdrop-filter backdrop-blur-md"
              >
                <div className="flex items-center">
                  <span>Owner Name</span>
                  <FaSort className="ml-2 text-gray-500" />
                </div>
              </Table.HeadCell>
              <Table.HeadCell className="px-2 sticky top-0 bg-[rgba(0,0,0,0.05)] backdrop-filter backdrop-blur-md">
                Owner DOB
              </Table.HeadCell>
              <Table.HeadCell className="px-2 sticky top-0 bg-[rgba(0,0,0,0.05)] backdrop-filter backdrop-blur-md">
                Owner’s Address
              </Table.HeadCell>
              <Table.HeadCell className="px-2 sticky top-0 bg-[rgba(0,0,0,0.05)] backdrop-filter backdrop-blur-md">
                Vehicle
              </Table.HeadCell>
              <Table.HeadCell className="px-2 sticky top-0 bg-[rgba(0,0,0,0.05)] backdrop-filter backdrop-blur-md">
                Plate
              </Table.HeadCell>
              <Table.HeadCell
                onClick={() => sortPermits('parkingPermitId')}
                className="px-2 cursor-pointer sticky top-0 bg-[rgba(0,0,0,0.05)] backdrop-filter backdrop-blur-md"
              >
                <div className="flex items-center">
                  <span>Zone</span>
                  <FaSort className="ml-2 text-gray-500" />
                </div>
              </Table.HeadCell>
              <Table.HeadCell
                onClick={() => sortPermits('effectiveDate')}
                className="px-2 cursor-pointer sticky top-0 bg-[rgba(0,0,0,0.05)] backdrop-filter backdrop-blur-md"
              >
                <div className="flex items-center">
                  <span>Issued Date</span>
                  <FaSort className="ml-2 text-gray-500" />
                </div>
              </Table.HeadCell>
              <Table.HeadCell
                onClick={() => sortPermits('expirationDate')}
                className="px-2 cursor-pointer sticky top-0 bg-[rgba(0,0,0,0.05)] backdrop-filter backdrop-blur-md"
              >
                <div className="flex items-center">
                  <span>Expiration</span>
                  <FaSort className="ml-2 text-gray-500" />
                </div>
              </Table.HeadCell>
              <Table.HeadCell className="px-2 sticky top-0 bg-[rgba(0,0,0,0.05)] backdrop-filter backdrop-blur-md">
                Fee
              </Table.HeadCell>
              <Table.HeadCell
                onClick={() => sortPermits('permitStatus')}
                className="px-2 cursor-pointer sticky top-0 bg-[rgba(0,0,0,0.05)] backdrop-filter backdrop-blur-md"
              >
                <div className="flex items-center">
                  <span>Status</span>
                  <FaSort className="ml-2 text-gray-500" />
                </div>
              </Table.HeadCell>
            </Table.Head>
            <Table.Body>
              {loading ? (
                <TableLoadingComponent numberofColumns={11} />
              ) : (
                permitsArray.map(permit => {
                  const isHighlighted = newPermitId === permit.id;
                  return (
                    <Table.Row
                      onClick={() => {
                        setSelectedPermit(permit);
                        setAddModalVisible(true);
                      }}
                      // className={`cursor-pointer group  even:bg-[rgba(0,0,0,0.05)] hover:bg-[rgba(0,0,0,0.1)] ${
                      //   isHighlighted ? 'animate-fadeHighlight' : ''
                      // }`}
                      className={`cursor-pointer group even:bg-[rgba(0,0,0,0.05)] ${
                        isStatusCellHovered ? '' : 'hover:bg-[rgba(0,0,0,0.1)]'
                      } ${isHighlighted ? 'animate-fadeHighlight' : ''}`}
                      style={{
                        animation: isHighlighted
                          ? 'fadeHighlight 3s ease-in-out'
                          : 'none',
                      }}
                    >
                      <Table.Cell>{permit?.permitNumber}</Table.Cell>
                      <Table.Cell>{permit?.ownerName || '---'}</Table.Cell>
                      <Table.Cell className="w-28">
                        {permit?.ownerDOB || '---'}
                      </Table.Cell>
                      <Table.Cell>
                        {permit?.ownerAddress ||
                        permit?.ownerCity ||
                        permit?.ownerZip ||
                        permit?.ownerState
                          ? `${permit?.ownerAddress || ''} ${permit?.ownerCity || ''} ${permit?.ownerState || ''} ${permit?.ownerZip || ''}`
                          : '---'}
                      </Table.Cell>
                      <Table.Cell>
                        {!permit?.color && !permit?.make && !permit?.model
                          ? '---'
                          : `${permit?.color || ''} ${permit?.make || ''} ${permit?.model || ''}`}
                      </Table.Cell>
                      <Table.Cell className="uppercase">
                        {permit?.licensePlateNumber || permit?.licensePlateState
                          ? `${permit?.licensePlateNumber} ${permit?.licensePlateState}`
                          : '---'}
                      </Table.Cell>
                      {renderParkingPermit(permit?.parkingPermitId)}
                      <Table.Cell className="w-28">
                        {permit?.effectiveDate || '---'}
                      </Table.Cell>
                      <Table.Cell className="w-28">
                        {permit?.expirationDate || '---'}
                      </Table.Cell>
                      <Table.Cell>{permit?.fee || '---'}</Table.Cell>
                      <Table.Cell
                        className="whitespace-nowrap w-[240px]"
                        onClick={e => e.stopPropagation()}
                      >
                        <span
                          onMouseEnter={() => setIsStatusCellHovered(true)}
                          onMouseLeave={() => setIsStatusCellHovered(false)}
                        >
                          <DropdownCell
                            statusesRequiringConfirmation={[
                              'EXPIRED',
                              'ACTIVE',
                            ]}
                            defaultStatus={permit?.permitStatus?.toUpperCase()}
                            statusOptions={permitStatusOptions}
                            id={permit?.id}
                            onStatusChange={(id, newStatus) => {
                              if (newStatus === 'EXPIRED') {
                                setCurrentPermitId(id);
                                setShowRefundInput(permit?.paidByStripe);
                                setModalVisible(true);
                              } else if (newStatus === 'ACTIVE') {
                                setCurrentPermitId(id);
                                setPaymentModalVisible(true);
                              } else {
                                changeStatus(id, newStatus);
                              }
                            }}
                          />
                        </span>
                      </Table.Cell>
                    </Table.Row>
                  );
                })
              )}
              {fetchingMore && (
                <TableLoadingComponent numberofRows={4} numberofColumns={10} />
              )}
            </Table.Body>
          </Table>
        )}
      </div>
      <ConfirmationModal
        showRefundInput={showRefundInput}
        loading={updatingStatus || refundingPayment}
        title={`Expire ${getChangedLabel(organizationType, true)}?`}
        description={`Are you sure you want to expire this ${getChangedLabel(organizationType)}?`}
        subDescription="It will remain in the records, but its expiration date and status will be changed to expired on today’s date."
        isOpen={modalVisible}
        onClose={() => {
          setCurrentPermitId(null);
          setModalVisible(false);
        }}
        onConfirm={refundAmount => {
          if (refundAmount) {
            handleRefund(refundAmount);
          } else {
            changeStatus(currentPermitId, 'EXPIRED');
            refetch();
          }
        }}
        buttonTitle="Expire"
        icon={<MdOutlineTimer className="hover:text-[black] m-1" size={20} />}
      />
      <ConfirmationModal
        title={`Delete ${getChangedLabel(organizationType, true)}?`}
        isOpen={deleteModalVisible}
        onClose={() => setDeleteModalVisible(false)}
        onConfirm={removePermit}
        message={`Are you sure you want to delete ${getChangedLabel(organizationType, false)} #${deletePermitData?.permitNumber}?`}
        loading={removing}
        icon={<RiDeleteBin6Line className="w-5 h-5 mr-1" />}
      />
      <ActivatePermitModal
        content={{
          heading: `Activate ${getChangedLabel(organizationType, true)}?`,
          description1: (
            <>
              If the customer is paying for the{' '}
              {getChangedLabel(organizationType)} with a{' '}
              <strong>credit card</strong>, press “Take Payment” - this will
              redirect you to the checkout screen to complete the transaction.
            </>
          ),
          description2: (
            <>
              If the customer is using any{' '}
              <strong>other form of payment</strong> outside Dashboard (e.g.
              check, cash, money order, etc), press “No Payment”, and the{' '}
              {getChangedLabel(organizationType)} will be activated immediately.
            </>
          ),
        }}
        isOpen={paymentModalVisible}
        onClose={() => setPaymentModalVisible(false)}
        onConfirm={() => getPaymentIntent(currentPermitId)}
        onNoPayment={() => {
          changeStatus(currentPermitId, 'ACTIVE');
          setPaymentModalVisible(false);
          refetch();
        }}
      />
      <AddPermitModal
        setDeleteModalVisible={setDeleteModalVisible}
        setDeletePermitData={setDeletePermitData}
        setNewPermitId={setNewPermitId}
        isOpen={addModalVisible}
        onClose={() => {
          setSelectedPermit(null);
          setAddModalVisible(false);
        }}
        refetch={refetch}
        permitDataFromProps={selectedPermit}
      />
    </div>
  );
};

export default PermitsTable;
