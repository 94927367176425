import React from 'react';
import Select from 'react-select';

const StatusFilterDropdown = ({
  statusCounts,
  onStatusChange,
  statusOptions,
}) => {
  // Format status text to readable format
  // Compute total count
  const totalCount = (statusCounts || []).reduce(
    (total, status) => total + status.count,
    0
  );

  const defaultOption = { value: '', label: `All Statuses`, count: totalCount };

  // Merge statusOptions with counts
  const options = [
    defaultOption,
    ...statusOptions.map(status => {
      const matchedStatus = statusCounts?.find(
        item => item.status === status.value.toLowerCase()
      );
      return {
        ...status,
        count: matchedStatus ? matchedStatus.count : 0,
      };
    }),
  ];
  // Default option with total count

  return (
    <Select
      options={options}
      defaultValue={defaultOption}
      onChange={selectedOption => onStatusChange(selectedOption?.value)}
      isSearchable={false}
      placeholder="Select Status"
      getOptionLabel={({ label, count }) => (
        <div
          style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
        >
          <span
            style={{
              color: 'black',
              opacity: 0.5, // 50% opacity for count
              marginRight: '10px', // Space between count and label
            }}
          >
            {label === 'All Statuses' ? totalCount : count}
          </span>
          <span style={{ color: 'black' }}>{label}</span>
        </div>
      )}
      styles={{
        control: base => ({
          ...base,
          backgroundColor: '#FAFAFA',
          borderColor: 'transparent',
          boxShadow: 'none',
          borderRadius: 100,
          '&:hover': {
            backgroundColor: 'rgba(0,0,0,0.1)',
            borderColor: '#ccc',
          },
          height: 36,
          fontSize: 14,
          minWidth: 192,
          paddingLeft: 24,
          paddingRight: 24,
        }),
        singleValue: base => ({
          ...base,
          color: 'black', // Ensures the selected value text is black
        }),
        option: (provided, state) => ({
          ...provided,
          backgroundColor: state.isSelected
            ? 'transparent'
            : provided.backgroundColor,
          color: 'black', // Set color to black for option text
          '&:hover': {
            backgroundColor: '#e0e0e0', // Grey hover effect on options
            cursor: 'pointer',
          },
        }),
        indicatorSeparator: () => ({ display: 'none' }),
      }}
    />
  );
};

export default StatusFilterDropdown;
