import React from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as yup from 'yup';
import { STATES, TERMS, toastConfig } from '../utils/constants';
import { ClipLoader } from 'react-spinners';
import { gql, useMutation } from '@apollo/client';
import { useAuth } from '../context/AuthContext';
import {
  calculateExpirationDate,
  getChangedLabel,
} from '../utils/helperFunctions';
import { toast } from 'react-toastify';
import { UPDATE_PERMIT_STATUS } from './PermitsTable';
import { RiDeleteBin6Line } from 'react-icons/ri';

const validationSchema = yup.object().shape({
  parkingPermitId: yup.string().required('Zone is required'),
  fee: yup.number().positive().integer().required('Fee is required'),
  effectiveDate: yup.date().required('Effective date is required'),
  term: yup.string().required('Term is required'),
  ownerName: yup.string().required('Owner name is required'),
  ownerDOB: yup.date().required('Owner date of birth is required'),
  ownerEmail: yup.string().required('Owner Email is required'),
  ownerPhone: yup.string().required('Owner Phone is required'),
  ownerAddress: yup.string().required('Owner address is required'),
  licensePlate: yup.string().required('License plate is required'),
  licensePlateState: yup.string().required('License plate state is required'),
  color: yup.string().required('Color is required'),
  make: yup.string().required('Make is required'),
  model: yup.string().required('Model is required'),
  ownerCity: yup.string().required('Owner city is required'),
  ownerZip: yup.string().required('ZIP code is required'),
  ownerState: yup.string().required('Owner state is required'),
  renewable: yup.boolean().notRequired(),
  comments: yup.string().notRequired(),
});
const CREATE_PERMIT = gql`
  mutation CreatePermit($input: CreatePermitInput!) {
    createPermit(createPermitInput: $input) {
      id
    }
  }
`;
const AddPermitModal = ({
  isOpen,
  onClose,
  permitDataFromProps,
  refetch,
  setNewPermitId,
  setDeletePermitData,
  setDeleteModalVisible,
}) => {
  const initialValues = {
    parkingPermitId: permitDataFromProps?.parkingPermitId || '',
    fee: permitDataFromProps?.fee || '',
    effectiveDate: permitDataFromProps?.effectiveDate || '',
    term: permitDataFromProps?.term || '',
    ownerName: permitDataFromProps?.ownerName || '',
    comments: permitDataFromProps?.comments || '',
    ownerDOB: permitDataFromProps?.ownerDOB || '',
    ownerEmail: permitDataFromProps?.ownerEmail || '',
    ownerPhone: permitDataFromProps?.ownerPhone || '',
    ownerAddress: permitDataFromProps?.ownerAddress || '',
    licensePlate: permitDataFromProps?.licensePlateNumber || '',
    licensePlateState: permitDataFromProps?.licensePlateState || '',
    color: permitDataFromProps?.color || '',
    make: permitDataFromProps?.make || '',
    model: permitDataFromProps?.modal || '',
    ownerCity: permitDataFromProps?.ownerCity || '',
    ownerZip: permitDataFromProps?.ownerZip || '',
    ownerState: permitDataFromProps?.ownerState || '',
    renewable: permitDataFromProps?.renewable || false,
    customerId: permitDataFromProps?.customerId || '',
    customerType: permitDataFromProps?.customerType || '',
  };
  const [createPermit] = useMutation(CREATE_PERMIT);
  const [updatePermit] = useMutation(UPDATE_PERMIT_STATUS);
  const { currentUser, organizationType } = useAuth();
  const customerTypes = currentUser?.municipality?.customerTypes;
  const parkingPermits = currentUser?.municipality?.parkingPermits;
  const showDeleteButton =
    currentUser?.municipality?.allowPermitChanges && !!permitDataFromProps;

  const submitTicket = async (values, { setSubmitting, resetForm }) => {
    try {
      const permitData = {
        parkingPermitId: values.parkingPermitId,
        fee: typeof values.fee === 'string' ? values.fee : String(values?.fee),
        effectiveDate: values.effectiveDate,
        term: values.term,
        ownerName: values.ownerName,
        ownerDOB: values.ownerDOB,
        ownerEmail: values.ownerEmail,
        ownerPhone: values.ownerPhone,
        ownerAddress: values.ownerAddress,
        licensePlateNumber: values.licensePlate,
        licensePlateState: values.licensePlateState,
        color: values.color,
        make: values.make,
        modal: values.model,
        ownerCity: values.ownerCity,
        ownerZip: values.ownerZip,
        ownerState: values.ownerState,
        renewable: values.renewable,
        customerId: values?.customerId,
        customerType: values?.customerType,
        comments: values?.comments,
        municipalityId: currentUser?.municipalityId,
        permitStatus: 'PENDING_PAYMENT',
        expirationDate:
          calculateExpirationDate(values.effectiveDate, values.term) || null,
      };
      let response;
      if (permitDataFromProps) {
        response = await updatePermit({
          variables: {
            updatePermitInput: {
              ...permitData,
              permitStatus: permitDataFromProps?.permitStatus,
              permitId: permitDataFromProps?.id,
            },
          },
        });
      } else {
        response = await createPermit({
          variables: {
            input: permitData,
          },
        });
        toast.success(
          `${getChangedLabel(organizationType, true)} created successfully`,
          toastConfig
        );
      }
      setNewPermitId(response?.data?.createPermit?.id);
      refetch();
      resetForm();
      onClose(); // Close the modal on successful submission
    } catch (err) {
      toast.error(
        `Error creating ${getChangedLabel(organizationType, false)}`,
        toastConfig
      );
      console.error(
        `Error submitting ${getChangedLabel(organizationType, false)}:`,
        err
      );
    } finally {
      setSubmitting(false);
    }
  };

  const handleSubmit = (values, { setSubmitting, resetForm }) => {
    submitTicket(values, { setSubmitting, resetForm });
  };

  if (!isOpen) return null;

  return (
    <div
      onClick={onClose}
      className="fixed inset-0 z-50 flex items-center justify-center bg-[rgba(0,0,0,0.5)] bg-opacity-75 overflow-y-auto"
    >
      <div
        onClick={e => e.stopPropagation()}
        className="w-[670px] bg-[#EDEDED] rounded-md shadow-lg p-9 max-h-[90vh] overflow-y-auto"
      >
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ setFieldValue, isSubmitting, values, errors, touched }) => (
            <Form>
              <h2 className="text-lg font-bold text-center mb-9">
                {getChangedLabel(organizationType, true)}
              </h2>
              <div className="flex flex-row items-center flex-1 ">
                <div className="flex flex-1 flex-col mr-2 relative">
                  <Field
                    as="select"
                    className={`${errors.parkingPermitId && touched.parkingPermitId ? 'border-red-500' : ''} w-full focus:outline-none focus:ring-1 focus:ring-[rgba(0,0,0,0.2)] focus:border-transparent h-[50px] rounded-md px-4 border border-1 text-sm bg-white  border-[rgba(0,0,0,0.1)]`}
                    name="parkingPermitId"
                    onChange={e => {
                      const selectedZone = parkingPermits.find(
                        permitZone => permitZone.id === e.target.value
                      );
                      const termValue = values.term;
                      let fee;

                      if (termValue === 'sixMonth') {
                        fee = selectedZone?.['sixMonthFee'] || 0;
                      } else if (termValue === 'oneYear') {
                        fee = selectedZone?.['oneYearFee'] || 0;
                      } else if (termValue === 'twoYear') {
                        fee = selectedZone?.['twoYearFee'] || 0;
                      }
                      setFieldValue('parkingPermitId', e.target.value);
                      setFieldValue('fee', fee);
                    }}
                    value={values.parkingPermitId}
                  >
                    <option value="" disabled hidden>
                      Zone
                    </option>
                    {parkingPermits.map((zone, index) => (
                      <option key={index} value={zone.id}>
                        {zone.zone}
                      </option>
                    ))}
                  </Field>
                  <div className="h-5">
                    <ErrorMessage
                      name="parkingPermitId"
                      component="div"
                      className="text-red-500 text-xs "
                    />
                  </div>
                  {values.parkingPermitId && (
                    <label className="absolute text-[11px] ml-4 text-[rgba(0,0,0,0.5)]">
                      Zone
                    </label>
                  )}
                </div>
                <div className="flex flex-1 flex-col mr-2 relative">
                  <Field
                    name="term"
                    as="select"
                    placeholder="Term"
                    className={`${errors.term && touched.term ? 'border-red-500' : ''} w-full focus:outline-none focus:ring-1 focus:ring-[rgba(0,0,0,0.2)] focus:border-transparent h-[50px] rounded-md px-4 border border-1 text-sm bg-white  border-[rgba(0,0,0,0.1)]`}
                    onChange={e => {
                      const selectedTerm = e.target.value;
                      const selectedZone = parkingPermits.find(
                        permitZone => permitZone.id === values.parkingPermitId
                      );
                      let fee;

                      if (selectedTerm === 'sixMonth') {
                        fee = selectedZone?.['sixMonthFee'] || 0;
                      } else if (selectedTerm === 'oneYear') {
                        fee = selectedZone?.['oneYearFee'] || 0;
                      } else if (selectedTerm === 'twoYear') {
                        fee = selectedZone?.['twoYearFee'] || 0;
                      }

                      setFieldValue('term', selectedTerm);
                      setFieldValue('fee', fee);
                    }}
                    value={values.term ? values.term : ''}
                  >
                    <option value="" disabled hidden>
                      Term
                    </option>
                    {TERMS.map((term, index) => (
                      <option key={index} value={term.value}>
                        {term.label}
                      </option>
                    ))}
                  </Field>
                  <div className="h-5">
                    <ErrorMessage
                      className="text-red-500 text-xs "
                      name="term"
                      component="div"
                    />
                  </div>
                  {values.term && (
                    <label className="absolute text-[11px] ml-4 text-[rgba(0,0,0,0.5)]">
                      Term
                    </label>
                  )}
                </div>
                <div className="flex flex-1 flex-col mr-2 relative">
                  <div className="relative w-full">
                    <div className="absolute inset-y-0 left-0 pl-4 flex items-center pointer-events-none">
                      <span className="text-gray-500 text-sm">$</span>
                    </div>
                    <Field
                      value={values.fee}
                      name="fee"
                      type="number"
                      placeholder="Fee"
                      className={`${errors.fee && touched.fee ? 'border-red-500' : ''} w-full pl-[26px] focus:outline-none focus:ring-1 focus:ring-[rgba(0,0,0,0.2)] focus:border-transparent h-[50px] rounded-md px-4 border border-1 text-sm bg-white border-[rgba(0,0,0,0.1)]`}
                    />
                  </div>
                  <div className="h-5">
                    <ErrorMessage
                      className="text-red-500 text-xs mt-1"
                      name="fee"
                      component="div"
                    />
                  </div>
                  {values.fee && (
                    <label className="absolute text-[11px] ml-4 text-[rgba(0,0,0,0.5)]">
                      Fee
                    </label>
                  )}
                </div>
                <div className="flex flex-1 flex-col relative">
                  <Field
                    name="effectiveDate"
                    type="date"
                    placeholder="DD/MM/YYYY"
                    className={`${errors.effectiveDate && touched.effectiveDate ? 'border-red-500' : ''} w-full focus:outline-none focus:ring-1 focus:ring-[rgba(0,0,0,0.2)] focus:border-transparent h-[50px] rounded-md px-4 border border-1 text-sm bg-white  border-[rgba(0,0,0,0.1)]`}
                  />
                  <div className="h-5">
                    <ErrorMessage
                      className="text-red-500 text-xs "
                      name="effectiveDate"
                      component="div"
                    />
                  </div>

                  <label className="absolute text-[11px] ml-4 text-[rgba(0,0,0,0.5)]">
                    Effective Date
                  </label>
                </div>
              </div>
              <div>
                <label className="text-black text-sm flex items-center cursor-pointer">
                  <input
                    name="renewable"
                    type="checkbox"
                    onChange={e => setFieldValue('renewable', e.target.checked)}
                    checked={values?.renewable}
                    style={{
                      accentColor: '#F75474',
                    }}
                    className="mr-2 w-4 h-4 focus:outline-none focus:ring-0 focus:ring-offset-0 rounded-sm"
                  />
                  Enable Auto-Renewal
                </label>
                <span className="text-sm text-black text-opacity-50 block mt-2">
                  Will renew the{' '}
                  {getChangedLabel(organizationType, false, false)} for the same
                  term and the same fee upon the end of the current term
                </span>
              </div>
              <div className="flex flex-row flex-1 items-center mt-9">
                <div className="flex flex-1 flex-col mr-2 relative">
                  <Field
                    id="licensePlate"
                    style={{ textTransform: 'uppercase' }}
                    name="licensePlate"
                    type="text"
                    placeholder=""
                    className={`${errors.licensePlate && touched.licensePlate ? 'border-red-500' : ''} place w-full focus:outline-none focus:ring-1 focus:ring-[rgba(0,0,0,0.2)] focus:border-transparent h-[50px] rounded-md px-4 border border-1 text-sm bg-white  border-[rgba(0,0,0,0.1)]`}
                  />
                  <div className="h-5">
                    <ErrorMessage
                      className="text-red-500 text-xs "
                      name="licensePlate"
                      component="div"
                    />
                  </div>
                  {!values.licensePlate && (
                    <label
                      htmlFor="licensePlate"
                      className="cursor-text absolute text-sm ml-4 text-gray-500 mt-[15px] "
                    >
                      License Plate
                    </label>
                  )}
                  {values.licensePlate && (
                    <label className="absolute text-[11px] ml-4 text-[rgba(0,0,0,0.5)]">
                      License Plate
                    </label>
                  )}
                </div>

                <div className="flex flex-1 flex-col relative">
                  <Field
                    name="licensePlateState"
                    as="select"
                    placeholder="State"
                    className={`${errors.licensePlateState && touched.licensePlateState ? 'border-red-500' : ''} w-full focus:outline-none focus:ring-1 focus:ring-[rgba(0,0,0,0.2)] focus:border-transparent h-[50px] rounded-md px-4 border border-1 text-sm bg-white  border-[rgba(0,0,0,0.1)]`}
                  >
                    <option value="" disabled hidden>
                      License Plate State
                    </option>
                    {STATES.map((state, index) => (
                      <option key={index} value={state.value}>
                        {state.label}
                      </option>
                    ))}
                  </Field>
                  <div className="h-5">
                    <ErrorMessage
                      className="text-red-500 text-xs "
                      name="licensePlateState"
                      component="div"
                    />
                  </div>
                  {values.licensePlateState && (
                    <label className="absolute text-[11px] ml-4 text-[rgba(0,0,0,0.5)]">
                      State
                    </label>
                  )}
                </div>
              </div>
              <div className="flex flex-1 flex-row items-center ">
                <div className="flex flex-1 mr-2 flex-col relative">
                  <Field
                    name="color"
                    type="text"
                    placeholder="Color"
                    className={`${errors.color && touched.color ? 'border-red-500' : ''} w-full focus:outline-none focus:ring-1 focus:ring-[rgba(0,0,0,0.2)] focus:border-transparent h-[50px] rounded-md px-4 border border-1 text-sm bg-white  border-[rgba(0,0,0,0.1)]`}
                  />
                  <div className="h-5">
                    <ErrorMessage
                      className="text-red-500 text-xs "
                      name="color"
                      component="div"
                    />
                  </div>
                  {values.color && (
                    <label className="absolute text-[11px] ml-4 text-[rgba(0,0,0,0.5)]">
                      Color
                    </label>
                  )}
                </div>
                <div className="flex flex-1 mr-2 flex-col relative">
                  <Field
                    name="make"
                    type="text"
                    placeholder="Make"
                    className={`${errors.make && touched.make ? 'border-red-500' : ''} w-full focus:outline-none focus:ring-1 focus:ring-[rgba(0,0,0,0.2)] focus:border-transparent h-[50px] rounded-md px-4 border border-1 text-sm bg-white  border-[rgba(0,0,0,0.1)]`}
                  />
                  <div className="h-5">
                    <ErrorMessage
                      className="text-red-500 text-xs "
                      name="make"
                      component="div"
                    />
                  </div>
                  {values.make && (
                    <label className="absolute text-[11px] ml-4 text-[rgba(0,0,0,0.5)]">
                      Make
                    </label>
                  )}
                </div>
                <div className="flex flex-1 flex-col relative">
                  <Field
                    name="model"
                    type="text"
                    placeholder="Model"
                    className={`${errors.model && touched.model ? 'border-red-500' : ''} w-full focus:outline-none focus:ring-1 focus:ring-[rgba(0,0,0,0.2)] focus:border-transparent h-[50px] rounded-md px-4 border border-1 text-sm bg-white  border-[rgba(0,0,0,0.1)]`}
                  />
                  <div className="h-5">
                    <ErrorMessage
                      className="text-red-500 text-xs "
                      name="model"
                      component="div"
                    />
                  </div>
                  {values.model && (
                    <label className="absolute text-[11px] ml-4 text-[rgba(0,0,0,0.5)]">
                      Model
                    </label>
                  )}
                </div>
              </div>
              <div className="flex flex-row items-center flex-1 mt-4">
                <div className="flex flex-1 flex-col mr-2 relative">
                  <Field
                    className={`${errors.ownerName && touched.ownerName ? 'border-red-500' : ''} w-full focus:outline-none focus:ring-1 focus:ring-[rgba(0,0,0,0.2)] focus:border-transparent h-[50px] rounded-md px-4 border border-1 text-sm bg-white  border-[rgba(0,0,0,0.1)]`}
                    name="ownerName"
                    type="text"
                    placeholder="Owner’s Name"
                  />
                  {values.ownerName && (
                    <label className="absolute text-[11px] ml-4 text-[rgba(0,0,0,0.5)]">
                      Owner’s Name
                    </label>
                  )}
                  <div className="h-5">
                    <ErrorMessage
                      className="text-red-500 text-xs "
                      name="ownerName"
                      component="div"
                    />
                  </div>
                </div>
                <div className="flex flex-1 flex-col relative">
                  <Field
                    name="ownerDOB"
                    type="date"
                    placeholder="DD/MM/YYYY"
                    className={`${errors.ownerDOB && touched.ownerDOB ? 'border-red-500' : ''} w-full focus:outline-none focus:ring-1 focus:ring-[rgba(0,0,0,0.2)] focus:border-transparent h-[50px] rounded-md px-4 border border-1 text-sm bg-white  border-[rgba(0,0,0,0.1)]`}
                  />
                  <div className="h-5">
                    <ErrorMessage
                      className="text-red-500 text-xs "
                      name="ownerDOB"
                      component="div"
                    />
                  </div>
                  <label className="absolute text-[11px] ml-4 text-[rgba(0,0,0,0.5)]">
                    Owner’s DOB
                  </label>
                </div>
              </div>
              <div className="flex flex-row items-center flex-1 ">
                <div className="flex flex-1 flex-col  mr-2 relative">
                  <Field
                    name="ownerEmail"
                    type="email"
                    className={`${errors.ownerEmail && touched.ownerEmail ? 'border-red-500' : ''} w-full focus:outline-none focus:ring-1 focus:ring-[rgba(0,0,0,0.2)] focus:border-transparent h-[50px] rounded-md px-4 border border-1 text-sm bg-white  border-[rgba(0,0,0,0.1)]`}
                    placeholder="Email"
                  />
                  <div className="h-5">
                    <ErrorMessage
                      className="text-red-500 text-xs "
                      name="ownerEmail"
                      component="div"
                    />
                  </div>
                  {values.ownerEmail && (
                    <label className="absolute text-[11px] ml-4 text-[rgba(0,0,0,0.5)]">
                      Email
                    </label>
                  )}
                </div>
                <div className="flex flex-1 flex-col relative">
                  <Field
                    className={`${errors.ownerPhone && touched.ownerPhone ? 'border-red-500' : ''} w-full focus:outline-none focus:ring-1 focus:ring-[rgba(0,0,0,0.2)] focus:border-transparent h-[50px] rounded-md px-4 border border-1 text-sm bg-white  border-[rgba(0,0,0,0.1)]`}
                    name="ownerPhone"
                    type="text"
                    placeholder="Phone"
                  />
                  {values.ownerPhone && (
                    <label className="absolute text-[11px] ml-4 text-[rgba(0,0,0,0.5)]">
                      Phone
                    </label>
                  )}
                  <div className="h-5">
                    <ErrorMessage
                      className="text-red-500 text-xs "
                      name="ownerPhone"
                      component="div"
                    />
                  </div>
                </div>
              </div>
              <div className="flex flex-row items-center flex-1 ">
                <div className="flex flex-1 flex-col mr-2 relative">
                  <Field
                    name="customerId"
                    type="text"
                    placeholder="Customer Id"
                    className={`${errors.customerId && touched.customerId ? 'border-red-500' : ''} w-full focus:outline-none focus:ring-1 focus:ring-[rgba(0,0,0,0.2)] focus:border-transparent h-[50px] rounded-md px-4 border border-1 text-sm bg-white  border-[rgba(0,0,0,0.1)]`}
                  />
                  <div className="h-5">
                    <ErrorMessage
                      className="text-red-500 text-xs "
                      name="customerId"
                      component="div"
                    />
                  </div>
                  {values.customerId && (
                    <label className="absolute text-[11px] ml-4 text-[rgba(0,0,0,0.5)]">
                      Customer Id
                    </label>
                  )}
                </div>
                <div className="flex flex-1 flex-col relative">
                  <Field
                    name="customerType"
                    as="select"
                    placeholder="Customer Type"
                    className={`${errors.customerType && touched.customerType ? 'border-red-500' : ''} w-full focus:outline-none focus:ring-1 focus:ring-[rgba(0,0,0,0.2)] focus:border-transparent h-[50px] rounded-md px-4 border border-1 text-sm bg-white  border-[rgba(0,0,0,0.1)]`}
                  >
                    <option value="" disabled hidden>
                      Customer Type
                    </option>
                    {customerTypes.map((type, index) => (
                      <option key={index} value={type.customerType}>
                        {type.customerType}
                      </option>
                    ))}
                  </Field>
                  <div className="h-5">
                    <ErrorMessage
                      className="text-red-500 text-xs "
                      name="customerType"
                      component="div"
                    />
                  </div>
                  {values.customerType && (
                    <label className="absolute text-[11px] ml-4 text-[rgba(0,0,0,0.5)]">
                      Customer Type
                    </label>
                  )}
                </div>
              </div>
              <div className="flex flex-1 flex-col mt-4 relative">
                <Field
                  className={`${errors.ownerAddress && touched.ownerAddress ? 'border-red-500' : ''} w-full focus:outline-none focus:ring-1 focus:ring-[rgba(0,0,0,0.2)] focus:border-transparent h-[50px] rounded-md px-4 border border-1 text-sm bg-white  border-[rgba(0,0,0,0.1)]`}
                  name="ownerAddress"
                  type="text"
                  placeholder="Owner’s Address"
                />
                {values.ownerAddress && (
                  <label className="absolute text-[11px] ml-4 text-[rgba(0,0,0,0.5)]">
                    Owner’s Address
                  </label>
                )}
                <div className="h-5">
                  <ErrorMessage
                    className="text-red-500 text-xs "
                    name="ownerAddress"
                    component="div"
                  />
                </div>
              </div>
              <div className="flex flex-1 flex-row">
                <div className="flex flex-[2] flex-col mr-2 relative">
                  <Field
                    className={`${errors.ownerCity && touched.ownerCity ? 'border-red-500' : ''} w-full focus:outline-none focus:ring-1 focus:ring-[rgba(0,0,0,0.2)] focus:border-transparent h-[50px] rounded-md px-4 border border-1 text-sm bg-white  border-[rgba(0,0,0,0.1)]`}
                    name="ownerCity"
                    type="text"
                    placeholder="City"
                  />
                  {values.ownerCity && (
                    <label className="absolute text-[11px] ml-4 text-[rgba(0,0,0,0.5)]">
                      City
                    </label>
                  )}
                  <div className="h-5">
                    <ErrorMessage
                      className="text-red-500 text-xs "
                      name="ownerCity"
                      component="div"
                    />
                  </div>
                </div>
                <div className="flex flex-1 flex-col mr-2 relative">
                  <Field
                    name="ownerState"
                    as="select"
                    placeholder="State"
                    className={`${errors.ownerState && touched.ownerState ? 'border-red-500' : ''} w-full focus:outline-none focus:ring-1 focus:ring-[rgba(0,0,0,0.2)] focus:border-transparent h-[50px] rounded-md px-4 border border-1 text-sm bg-white  border-[rgba(0,0,0,0.1)]`}
                  >
                    <option value="" disabled hidden>
                      State
                    </option>
                    {STATES.map((state, index) => (
                      <option key={index} value={state.value}>
                        {state.label}
                      </option>
                    ))}
                  </Field>
                  <div className="h-5">
                    <ErrorMessage
                      className="text-red-500 text-xs "
                      name="ownerState"
                      component="div"
                    />
                  </div>
                  {values.ownerState && (
                    <label className="absolute text-[11px] ml-4 text-[rgba(0,0,0,0.5)]">
                      State
                    </label>
                  )}
                </div>
                <div className="flex flex-1 flex-col relative">
                  <Field
                    className={`${errors.ownerZip && touched.ownerZip ? 'border-red-500' : ''} w-full focus:outline-none focus:ring-1 focus:ring-[rgba(0,0,0,0.2)] focus:border-transparent h-[50px] rounded-md px-4 border border-1 text-sm bg-white  border-[rgba(0,0,0,0.1)]`}
                    name="ownerZip"
                    type="text"
                    placeholder="ZIP Code"
                  />
                  {values.ownerZip && (
                    <label className="absolute text-[11px] ml-4 text-[rgba(0,0,0,0.5)]">
                      ZIP Code
                    </label>
                  )}
                  <div className="h-5">
                    <ErrorMessage
                      className="text-red-500 text-xs "
                      name="ownerZip"
                      component="div"
                    />
                  </div>
                </div>
              </div>
              <div className="flex flex-1 flex-row mt-4">
                <div className=" flex-col flex flex-1 relative">
                  <Field
                    name="comments"
                    type="text"
                    as="textarea"
                    placeholder="Comments"
                    className={`pt-3 h-[110px] w-full focus:outline-none focus:ring-1 focus:ring-[rgba(0,0,0,0.2)] focus:border-transparent rounded-md px-4 border border-1 text-sm bg-white  border-[rgba(0,0,0,0.1)]`}
                  />
                  <div className="h-5">
                    <ErrorMessage
                      className="text-red-500 text-xs mt-[-6px]"
                      name="comments"
                      component="div"
                    />
                  </div>
                  {values.comments && (
                    <label className="absolute text-[11px] ml-4 text-[rgba(0,0,0,0.5)]">
                      Comments
                    </label>
                  )}
                </div>
              </div>
              <div className="h-[1px] bg-[rgba(0,0,0,0.1)] mb-6 mt-1" />
              <div className="flex flex-1  items-center">
                {showDeleteButton ? (
                  <button
                    className="flex flex-row items-center px-9 py-[10px] text-sm font-semibold rounded-full mr-4 bg-[rgba(0,0,0,0.05)] hover:bg-[rgba(0,0,0,0.15)]"
                    type="button"
                    onClick={() => {
                      onClose();
                      setDeletePermitData(permitDataFromProps);
                      setDeleteModalVisible(true);
                    }}
                  >
                    <RiDeleteBin6Line className="w-4 h-4 mr-3" />
                    Delete
                  </button>
                ) : (
                  <div />
                )}
                <div className="flex flex-1 justify-end items-center">
                  <button
                    className="px-9 py-[10px] text-sm font-semibold rounded-full mr-4 bg-[rgba(0,0,0,0.05)] hover:bg-[rgba(0,0,0,0.15)]"
                    type="submit"
                    onClick={onClose}
                  >
                    Cancel
                  </button>
                  <button
                    className="px-9 py-[10px] text-sm font-semibold rounded-full bg-[#FACE4F] hover:bg-[#D9B345] flex items-center"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? (
                      <>
                        <ClipLoader
                          size={18}
                          color={'#000000'}
                          className="mr-2"
                        />
                        Submitting...
                      </>
                    ) : (
                      'Submit'
                    )}
                  </button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default AddPermitModal;
